<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :rules="rules"
        :style="`max-width: ${maxWidth};`"
        :label="label"
        :append-icon="appendIcon"
        :disabled="disabled"
        :hide-details="hideDetails"
        :outlined="outlined"
        :filled="filled"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      no-title
      :disabled="disabled"
      :allowed-dates="allowedDates"
      scrollable
      @change="onChange"
    />
  </v-menu>
</template>

<script>
export default {
  name: "VMenuDatePicker",
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: [String, Boolean],
      default: "mdi-calendar-range"
    },
    label: {
      type: String,
      required: false
    },
    maxWidth: {
      type: String,
      default: "100%"
    },
    rules: {
      type: [Array, Function],
      required: false
    },
    allowedDates: {
      type: Function,
      required: false,
      default: null
    }
  },
  data() {
    return {
      date: this.value,
      menu: false
    };
  },
  watch: {
    value() {
      this.date = this.value;
    }
  },
  methods: {
    onChange() {
      this.$refs.menu.save(this.date);
      this.$emit("input", this.date);
    }
  }
};
</script>
